<template>
  <div>
    <!-- <ProgressTracker></ProgressTracker> -->
    <section class="section has-text-centered" v-if="oldIE !== -1 || oldBrowser === true">
      <div class="container">
        <h1 class="title is-size-1">
          You have a very old web browser.
        </h1>
        <h2 class="title is-size-2">
          Unfortunately, that means this form won't function properly for you.
          Please upgrade your browser to use this form.
        </h2>
      </div>
    </section>
    <section class="section has-text-centered" v-else-if="blankLinksEnabled">
      <div class="container">
        <h1 class="title is-size-1 is-size-2-mobile">
          Invalid link!
        </h1>
        <h2 class="title is-size-2 is-size-4-mobile" v-html="blankLinksEnabled" />
      </div>
    </section>
    <div v-else-if="notExpired">
      <Intro />
      <Rules />
      <ValidationObserver
        tag="form"
        id="survey"
        name="survey"
        class="form"
        ref="observer"
        @keydown.enter.prevent=""
      >
          <Layout />
          <Signature />
          <Submit />
      </ValidationObserver>
    </div>
    <section id="expired" class="section has-text-centered" v-else>
      <div class="container">
        <h1 class="title is-size-1">
          Survey form has expired!
        </h1>
      </div>
    </section>
  <Footer />
  </div>
</template>

<script>
import Intro from '@/components/Intro.vue';
import Rules from '@/components/Rules.vue';
import Layout from '@/components/Layout.vue';
import Signature from '@/components/Signature.vue';
import Submit from '@/components/Submit.vue';
import Footer from '@/components/Footer.vue';
// import ProgressTracker from '@/components/ProgressTracker.vue';
import { mapState } from 'vuex';
import facebookPixel from '@/lib/facebookPixel';
import linkedInInsights from '@/lib/linkedInInsights';

export default {
  components: {
    Intro,
    Footer,
    Rules,
    Layout,
    Signature,
    Submit,
    // ProgressTracker,
  },
  computed: {
    ...mapState([
      'prepopulation',
    ]),
    notExpired() {
      return new Date() < new Date(this.$store.state.expiration_date);
    },
    oldIE() {
      if (/MSIE (\d+\.\d+);/.test(navigator.userAgent)) {
        return new Number(RegExp.$1); /* eslint-disable-line */
      }
      return -1;
    },
    oldBrowser() {
      return window.FormData === undefined;
    },
    blankLinksEnabled() {
      const { blank_surveys } = this.$store.state;
      const { secret } = this.$route.params;

      /**
       * If we're at the root and blankLinks is true, show the survey form
       * Otherwise, show the error. This means we're at the root and we
       * have blank survey forms disabled in our config.
       *
       * We always let "secrets" through, and try to match them up to
       * prepopulation values if possible. The "secret" always gets sent
       * to the API to try and match to a record.
       */

      // Checks if blank links are explicitly disabled and no secret is provided
      const nakedLink = blank_surveys === false && secret === undefined;

      // Makes sure the secret is valid as well
      const wrongSecret = blank_surveys === false
        && !Object.keys(this.prepopulation).includes(secret);

      if (nakedLink || wrongSecret) { /* eslint-disable-line camelcase */
        return 'Sorry, this link is invalid! Please use your custom link to access your survey form.';
      }

      return false;
    },
  },
  methods: {
    validate() {
      this.$refs.observer.validate();
    },
  },
  mounted() {
    facebookPixel();
    linkedInInsights();
  },
};
</script>
