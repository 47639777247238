<template>
  <section class="content">
    <div
      class="container columns is-multiline"
      v-for="(page, key) in allFields"
      :key="key"
    >
      <div
        v-for="field in page"
        :key="field.name"
        :class="computeFieldClass(field)"
      >
        <label
          :for="field.name"
          :class="computeLabelClass(field)"
          v-if="shouldShowLabel(field)"
          v-html="field.label"
        />

        <Nomination v-if="field.type === 'nomination'" />
        <Faces v-else-if="field.type === 'faces'" />
        <Experts v-else-if="field.type === 'asktheexpert'" />

        <div class="field-body" v-else-if="field.nested">
          <div class="field" v-for="subfield in field.nested" :key="subfield.name">
            <label :for="subfield.name" class="subfield-label" v-html="subfield.label" />
            <FormHtml :field="subfield" v-if="subfield.type === 'html'" />
            <FormSelect :field="subfield" v-else-if="subfield.type === 'select'" />
            <FormTextArea :field="subfield" v-else-if="subfield.type === 'textarea'" />
            <FormFile :field="subfield" v-else-if="field.type === 'file'" />
            <FormInput :field="subfield" v-else />
          </div>
        </div>
        <div class="field-body" v-else>
          <div class="field">
            <FormHtml :field="field" v-if="field.type === 'html'" />
            <FormSelect :field="field" v-else-if="field.type === 'select'" />
            <FormSlider :field="field" v-else-if="field.type === 'slider'" />
            <FormTextArea :field="field" v-else-if="field.type === 'textarea'" />
            <FormRadio :field="field" v-else-if="field.type === 'radio'" />
            <FormFancyRadio :field="field" v-else-if="field.type === 'fancy-radio'" />
            <FormCheckbox :field="field" v-else-if="field.type === 'checkbox'" />
            <FormFile :field="field" v-else-if="field.type === 'file'" />
            <FormInput :field="field" v-else />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  FormInput, FormTextArea, FormCheckbox, FormHtml, FormRadio,
  FormSelect, FormSlider, FormFancyRadio, FormFile,
} from 'djx-form';

export default {
  computed: {
    ...mapGetters({
      allFields: 'fields',
    }),
  },
  methods: {
    computeFieldClass(field) {
      const classes = ['is-horizontal', 'column', 'is-12', 'is-marginless', `${field.type}-container`];
      if (!['nomination', 'faces'].includes(field.type)) {
        classes.push('field');
      }
      if (field.labelAbove === true) {
        classes.shift(); // This is `is-horizontal`, as it's the first item
      }
      return classes;
    },
    computeLabelClass(field) {
      const classes = ['field-label', 'has-text-weight-bold'];

      if (!['radio'].includes(field.type)) {
        classes.push('is-normal');
      }

      return classes;
    },
    shouldShowLabel(field) {
      if (field.type === 'checkbox') {
        return field.options && field.options.length > 0;
      }
      return !['html', 'nomination', 'faces', 'asktheexpert'].includes(field.type) && field.label;
    },
  },
  components: {
    FormInput,
    FormSelect,
    FormSlider,
    FormTextArea,
    FormHtml,
    FormRadio,
    FormCheckbox,
    FormFancyRadio,
    FormFile,
    Nomination: () => import('@/components/Nomination.vue'),
    Faces: () => import('@/components/FacesOf/Faces.vue'),
    Experts: () => import('@/components/AskTheExpert/Experts.vue'),
  },
};
</script>
