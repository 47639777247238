<template>
  <section class="content container" v-if="affirmationPoints.length > 0">
    <h2>Confirmation</h2>
    <ul>
      <li v-for="field in affirmationPoints" :key="field" v-html="field" />
    </ul>
    <label class="checkbox has-text-weight-semibold">
      <ValidationProvider
        :rules="{ required: { allowFalse: false }}"
        name="affirmation"
        v-slot="{ errors }"
        slim
      >
        <input v-model="affirmed" type="checkbox" name="affirmation">
        I Affirm
        <p class="help is-danger" v-if="errors" v-html="errors[0]"></p>
      </ValidationProvider>
    </label>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    affirmed: false,
  }),
  computed: {
    ...mapState({
      affirmation: 'affirmation_points',
    }),
    affirmationPoints() {
      return this.affirmation ?? ['This is my one and only submission.'];
    },
  },
};
</script>
